<template>
  <div class="verify">
    <v-container fluid>
      <div class="welcome">
        <h1>Please Verify Your Identity</h1>
      </div>

      <!-- Loading -->
      <div class="loading" v-if="isLoading">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <!-- / Loading -->

      <div id="sumsub-websdk-container"></div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import sumsub from "../../../../api/sumsub";
import snsWebSdk from "@sumsub/websdk";
import axios from 'axios';

export default {
  data() {
    return {
      isLoading: false,
      accessToken: null,

      isVerifiedPoller: null
    };
  },
  computed: {
    ...mapGetters("auth", {
      user: "user",
    }),
  },
  created() {
    this.getAccessToken()
      .then((token) => {
        this.launchWebSdk(token, this.user.email, this.user.phone_number);
      })
      .catch((e) => {
        console.log(e);
      });

    this.startPolling();
  },
  beforeDestroy() {
    clearInterval(this.isVerifiedPoller);
    this.isVerifiedPoller = null;
  },
  methods: {
    ...mapActions('auth', {
      reloadUser: 'reloadUser'
    }),

    getAccessToken() {
      let vm = this;
      return new Promise((resolve, reject) => {
        vm.isLoading = true;
        vm.accessToken = null;
        sumsub
          .getAccessToken()
          .then((r) => {
            vm.isLoading = false;
            vm.accessToken = r.data;
            resolve(r.data.token);
          })
          .catch((e) => {
            console.log(e);
            vm.$toast.error(
              "Something went wrong. Please refresh the page to try again."
            );
            reject(e);
          });
      });
    },

    launchWebSdk(accessToken, applicantEmail, applicantPhone) {
      let snsWebSdkInstance = snsWebSdk
        .init(
          accessToken,
          // token update callback, must return Promise
          // Access token expired
          // get a new one and pass it to the callback to re-initiate the WebSDK
          () => this.getAccessToken()
        )
        .withConf({
          lang: "en", //language of WebSDK texts and comments (ISO 639-1 format)
          email: applicantEmail,
          phone: applicantPhone,
          // i18n: customI18nMessages, //JSON of custom SDK Translations
          uiConf: {},
        })
        .withOptions({ addViewportTag: false, adaptIframeHeight: true })
        // see below what kind of messages WebSDK generates
        .on("idCheck.stepCompleted", (payload) => {
          console.log("stepCompleted", payload);
        })
        .on("idCheck.onError", (error) => {
          console.log("onError", error);
        })
        .build();

      // you are ready to go:
      // just launch the WebSDK by providing the container element for it
      snsWebSdkInstance.launch("#sumsub-websdk-container");
    },

    startPolling() {
      let vm = this;
      vm.isVerifiedPoller = window.setInterval(() => {
        // console.log('polling...');
        axios.post(window.API_BASE + '/is-id-verified').then(r => {
          if (Boolean(r.data) == true) {
            clearInterval(vm.isVerifiedPoller);
            vm.isVerifiedPoller = null;
            vm.reloadUser().then(() => {
              vm.$router.push('/');
            }).catch(e => {
              console.log(e);
            });
          }
        });
      }, 10000);
    }
  },
};
</script>

<style scoped lang="scss">
.loading {
  text-align: center;
  padding-top: 20px;
  margin-top: 30px;
}

.verify {
  padding: 20px;

  .welcome {
    text-align: center;
  }
}
</style>